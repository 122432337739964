/* Pending Events */
section.pending-events-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

section.pending-events-section > h1 {
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}
