.calendarHeader h1 {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 20px;
}
.yearlyMonth .weekRow .dayCell:not(:first-child) {
  border-left: unset;
  min-height: unset;
  height: 30px;
}

.weekRow .dayCell:not(:first-child) {
  border-left: 1px solid #e9e9e9;
  min-height: 90px;
  height: unset;
}

.calendarWrapper > :nth-child(3) {
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

#dayHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.currentDay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightskyblue;
  color: black;
}
