.admin-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.admin-panel nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-panel section {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
