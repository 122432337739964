.events-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.event-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

.event-card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}
.event-card p {
  font-size: 1rem;
  font-weight: 400;
  padding-left: 30px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.event-card p > span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.event-card .buttons {
  margin-top: 20px;
}

.event-card {
  --d: 6px; /* folded part */
  --c: var(--ribbon-color); /* color */
  --f: 16px; /* ribbon font-size */
  position: relative;
}

.event-card::before {
  content: attr(data-ribbon);
  font-size: var(--f);
  font-weight: 500;
  /* I : position & coloration */
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(29.29%, -100%) rotate(45deg);
  transform-origin: bottom left;
  padding: 5px 35px calc(var(--d) + 5px);
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d) no-repeat var(--c);
  /* II : clipping */
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    calc(100% - var(--d)) calc(100% - var(--d)),
    var(--d) calc(100% - var(--d)),
    0 100%
  );
  /* III : masking */
  -webkit-mask: linear-gradient(135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom left,
    linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom right;
  -webkit-mask-size: 300vmax 300vmax;
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
}
