.services {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
  flex-wrap: wrap;
}

.services .container {
  width: 100%;
  max-width: 400px;
  height: 150px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  cursor: pointer;
}

.services .container img {
  height: 80px;
}

.services .container h2 {
  flex: 1;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.ribbon-tag {
  --d: 6px; /* folded part */
  --c: var(--ribbon-color); /* color */
  --f: 12px; /* ribbon font-size */
  position: relative;
}

.ribbon-tag::before {
  content: attr(data-ribbon);
  font-size: var(--f);
  font-weight: 500;
  /* I : position & coloration */
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(29.29%, -100%) rotate(45deg);
  transform-origin: bottom left;
  padding: 5px 35px calc(var(--d) + 5px);
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d) no-repeat var(--c);
  /* II : clipping */
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    calc(100% - var(--d)) calc(100% - var(--d)),
    var(--d) calc(100% - var(--d)),
    0 100%
  );

  /* III : masking */
  -webkit-mask: linear-gradient(135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom left,
    linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom right;
  -webkit-mask-size: 300vmax 300vmax;
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
}

@media screen and (max-width: 1440px) {
  .services {
    padding: 0px 20px;
    gap: 30px;
  }

  .services .container {
    max-width: 300px;
    height: 120px;
  }

  .services .container img {
    height: 60px;
  }

  .services .container h2 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .services {
    flex-direction: column;
    margin-top: 0px;
    padding: 0px 20px;
    gap: 30px;
  }

  .services .container {
    max-width: 300px;
    height: 120px;
  }

  .services .container img {
    height: 60px;
  }

  .services .container h2 {
    font-size: 1.3rem;
  }
}
