/* Add New Event */
section.add-new-event-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

section.add-new-event-section > h1 {
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

section.add-new-event-section .holiday-form {
  width: 100%;
  max-width: fit-content;
  padding: 20px 30px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: max-content max-content;
  row-gap: 20px;
  column-gap: 40px;
  justify-content: center;
  align-items: center;
}

section.add-new-event-section .holiday-form .container {
  display: contents;
}
section.add-new-event-section .holiday-form .container label {
  width: fit-content;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

section.add-new-event-section .holiday-form .container input {
  width: 100%;
  max-width: 300px;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

section.add-new-event-section .holiday-form button[type='submit'] {
  width: 100%;
  grid-column: span 2;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  section.add-new-event-section .holiday-form {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
  }

  section.add-new-event-section .holiday-form .container input {
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  section.add-new-event-section {
    padding: 10px;
  }
}
