@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --background-color: #f5f5f5;
}

main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

section.body-section {
  flex: 1;
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 50px 0;
}
